var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      class: _vm.missionClass,
      attrs: { column: "", "justify-center": "", "fill-height": "" },
    },
    [
      _c(
        "transition",
        { attrs: { name: "mission-result-transition", mode: "out-in" } },
        [
          _c(
            "v-layout",
            { attrs: { column: "", "justify-center": "", "fill-height": "" } },
            [_vm._t("default")],
            2
          ),
        ],
        1
      ),
      _c("HintPopup", {
        attrs: {
          isOpen: _vm.hintPopup,
          forceHint: _vm.forceHint,
          mode: "yes-no",
        },
        on: {
          onClose: function ($event) {
            _vm.hintPopup = false
          },
        },
      }),
      _c(
        "Popup",
        {
          attrs: { isOpen: _vm.timePopup, mode: "okay" },
          on: {
            onClose: function ($event) {
              _vm.timePopup = false
            },
            onResponse: function ($event) {
              _vm.timePopup = false
            },
          },
        },
        [_vm._v(" " + _vm._s(_vm.timePopupText) + " ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }