<template>
  <v-layout column justify-center fill-height :class="missionClass">
    <transition name="mission-result-transition" mode="out-in">
      <v-layout column justify-center fill-height>
        <slot />
      </v-layout>
    </transition>

    <HintPopup
      :isOpen="hintPopup"
      :forceHint="forceHint"
      mode="yes-no"
      @onClose="hintPopup = false"
    />

    <Popup
      :isOpen="timePopup"
      @onClose="timePopup = false"
      @onResponse="timePopup = false"
      :mode="'okay'"
    >
      {{ timePopupText }}
    </Popup>
  </v-layout>
</template>

<script>
import MissionType from "@shared/enums/Mission"
import { mapGetters } from "vuex"

import HintPopup from "@/components/Popups/HintPopup"
import Popup from "@/components/Popups/Popup"

export default {
  name: "Mission",
  components: {
    HintPopup,
    Popup
  },

  props: {
    points: [String, Number]
  },

  data() {
    return {
      hintPopup: false,
      forceHint: false,
      timePopup: false,
      timePopupText: null
    }
  },

  computed: {
    ...mapGetters(["missionHintsArray", "teamID"]),
    ...mapGetters({
      mission: "getCurrentMission"
    }),
    correctAnswerIdx() {
      return parseInt(this.mission?.multiCorrect) - 1
    },
    multipleChoiceAnswer() {
      const m = this.mission
      const array = []
      if (m.answer1) array.push(m.answer1)
      if (m.answer2) array.push(m.answer2)
      if (m.answer3) array.push(m.answer3)
      if (m.answer4) array.push(m.answer4)
      if (m.answer5) array.push(m.answer5)
      return array[this.correctAnswerIdx]
    },
    /** @returns {Record<string, boolean>} */
    missionClass() {
      const behavior = this.mission?.behavior ?? undefined

      return {
        mission: behavior !== MissionType.YouTube,
        "mission-youtube": behavior === MissionType.YouTube,
        "mission--giphy": behavior === MissionType.Giphy
      }
    },
    missionHintsArrayByTeam() {
      const arr = (this.missionHintsArray || []).filter(
        hint => hint.teamID == this.teamID && hint.missionID == this.mission?.id
      )
      return arr
    },

    hasHints() {
      return this.mission?.hints ? this.mission.hints[0] : null
    },
    behavior() {
      return this.$store.getters.getCurrentMission?.behavior
    }
  },

  async created() {
    this.$bus.$on("game-countdown-hint", this.forceHintPopup)
    this.$bus.$on("game-countdown-warning", this.timeWarning)
    this.$bus.$on("game-countdown-timesup", this.timesUp)
  },
  watch: {
    missionHintsArrayByTeam(value) {
      if (value.length === 1) this.hintPopup = true
    },
    mission() {
      this.hintPopup = false
    }
  },

  methods: {
    forceHintPopup() {
      if (this.hasHints) {
        this.forceHint = true
        this.hintPopup = true
      }
    },
    timeWarning(theTime) {
      if (this.mission.noPopup) return
      const behave = this.mission.behavior
      if (behave == "YouTube" || behave == "Info" || behave == "Video") return
      this.timePopup = true
      this.timePopupText =
        theTime + " left before we move on to the next challenge"
      setTimeout(() => {
        this.timePopup = false
      }, 4000)
    },
    timesUp() {
      if (this.mission.noPopup) return
      const behave = this.mission.behavior
      if (
        behave == "YouTube" ||
        behave == "Info" ||
        this.mission.duration == 0 ||
        behave == "Video"
      )
        return
      this.timePopup = true
      this.timePopupText = "Time's up!"
      console.log("mission", this.mission)

      if (this.mission.answer)
        this.timePopupText =
          this.timePopupText + " The answer is '" + this.mission.answer + "'"
      if (this.mission.behavior == "Multiple Choice")
        this.timePopupText =
          this.timePopupText +
          " The answer is '" +
          this.multipleChoiceAnswer +
          "'"
      setTimeout(() => {
        this.timePopup = false
      }, 4000)
    }
  }
}
</script>

<style lang="scss" scoped>
/* mission-result animation */
.mission-result-transition-enter-active,
.mission-result-transition-leave-active {
  transition: all ease 0.3s;
  opacity: 1;
}

.mission-result-transition-enter-active {
  transition-delay: 0.3s;
}

.mission-result-transition-enter,
.mission-result-transition-leave-to {
  opacity: 0;
}

.mission {
  @extend .rtb-border, .rtb-border-radius;
  position: relative;
  overflow: hidden;
  max-height: 365px;
}

.mission.hide-out {
  animation-name: hide-out;
  animation-delay: 1s;
  animation-duration: 1s;
  animation-iteration-count: forwards;
  animation-fill-mode: both;
}
@keyframes hide-out {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    transform: scale(0.6);
    opacity: 0;
  }
}

.mission-type {
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 0;
  margin-right: -8px;
  margin-top: -8px;
  color: $color-white;
  font-size: 1.2rem;
}

.the-hint {
  padding: 0 5px;
  cursor: pointer;
  &:hover {
    filter: brightness(1.1);
  }
}

.hints {
  @extend .rtb-border-radius;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  padding: 13px 10px 6px 20px;
  margin-left: -8px;
  margin-top: -8px;

  background-color: $color-black;
  background-color: $color-primary-dark;
  color: $color-white;
}

.mission-type-bg {
  @extend .rtb-border-radius;
  background-color: $color-primary-dark;
  padding-top: 13px;
  padding-bottom: 6px;
  padding-right: 20px;
  padding-left: 14px;
}

.mission-type-text {
  font-size: 13px;
  line-height: 14px;
  height: 14px;
  font-style: italic;
}

.mission-pts-text {
  font-size: 12px;
  line-height: 14px;
  height: 14px;
}

.mission-score {
  pointer-events: none;
  position: absolute;
  bottom: 0;
  left: 0;
  margin-left: -8px;
  margin-bottom: -8px;
  color: $secondary_accent_color;
  font-size: 1.2rem;
}

.mission-score-number {
  font-size: 18px;
  font-weight: bold;
  line-height: 20px;
  height: 20px;
}

.mission-score-bg {
  @extend .rtb-border-radius;
  color: $color-white;
  background-color: $color-primary-dark;
  padding-bottom: 14px;
  padding-top: 8px;
  padding-left: 18px;
  padding-right: 12px;
}
</style>
