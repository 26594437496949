<template>
  <Mission class="no-select" :title="title" :points="points">
    <template v-if="showResults">
      <PollResultContainer v-if="isPoll" />
      <ResultContainer v-else />
    </template>

    <slot v-else />
  </Mission>
</template>

<script>
import { mapGetters } from "vuex"
import MissionType from "@shared/enums/Mission"
import Mode from "@shared/enums/Mode"
import Mission from "@/components/Lobby/Play/Mission.vue"
export default {
  name: "MissionContainer",
  components: {
    Mission
  },
  computed: {
    ...mapGetters({
      mission: "getCurrentMission"
    }),
    isPoll() {
      return this.mission?.behavior === MissionType.Poll
    },
    showResults() {
      return this.mode === Mode.Results
    },
    title() {
      return this.currentMission?.title
    },
    points() {
      return this.currentMission?.points
    },
    currentMission() {
      return this.$store.getters.getCurrentMission
    }
  },
  props: {
    mode: String
  }
}
</script>
<style scoped>
.no-select {
  user-select: none;
}
</style>
