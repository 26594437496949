var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Mission",
    {
      staticClass: "no-select",
      attrs: { title: _vm.title, points: _vm.points },
    },
    [
      _vm.showResults
        ? [_vm.isPoll ? _c("PollResultContainer") : _c("ResultContainer")]
        : _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }